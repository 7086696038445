import React, { Component } from "react";
import "../App.css";

import facebook from "../assets/svg/sm/facebook.svg";
import instagram from "../assets/svg/sm/instagram.svg";
import twitter from "../assets/svg/sm/twitter.svg";
import linkedin from "../assets/svg/sm/linkedin.svg";
import email from "../assets/svg/sm/email.svg";
import whatsApp from "../assets/svg/sm/whatsApp.svg";

export default class Socialmedia extends Component {
  render() {
    return (
      <div className="icon-groups">
        <div className="socialmedia">
          <a href="mailto:team@saavik.uk">
            <img src={email} alt="email" />
          </a>
        </div>
        <div className="socialmedia">
          <img
            src={linkedin}
            alt="linkedin"
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/saavikltd/",
                "_blank"
              );
            }}
          />
        </div>
        <div className="socialmedia">
          <img
            src={twitter}
            alt="linkedin"
            onClick={() => {
              window.open("https://twitter.com/SaavikLtd", "_blank");
            }}
          />
        </div>
        <div className="socialmedia">
          <img
            src={instagram}
            alt="linkedin"
            onClick={() => {
              window.open("https://www.instagram.com/saavikltd/", "_blank");
            }}
          />
        </div>
        <div className="socialmedia">
          <img
            src={facebook}
            alt="facebook"
            onClick={() => {
              window.open("https://www.facebook.com/SaavikLtd", "_blank");
            }}
          />
        </div>
        <div className="is-mobile socialmedia">
          <img
            src={whatsApp}
            alt="whatsApp"
            onClick={() => {
              window.open("https://api.whatsapp.com/send?phone=+33695660048");
            }}
          />
        </div>
      </div>
    );
  }
}
