import React, { Component } from "react";
import "../App.css";
import firebase from "../services/firebase.js";
export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "email@example.com",
      error: " ",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const emailsRef = firebase.database().ref("emails");
    const email = {
      email: this.state.email,
    };

    emailsRef.push(email);
    this.setState({
      email: "",
    });
  }

  render() {
    return (
      <div className="contact">
        <div className="contact-description">
          Saavik Ltd is an international consultancy that specialises in
          political communication, visual storytelling, leadership training,
          linguistic mediation, and content creation.
        </div>
        <form className="form" onSubmit={this.handleSubmit}>
          <input
            type="name"
            name="email"
            className="input-area"
            placeholder="Sign up for our newsletter"
            onChange={this.handleChange}
            value={this.state.name}
          />
          <button
            className="button"
            type="submit"
            onClick={(e) => {
              if (window.alert("Your subscription has been confirmed."));
            }}
          >
            Send
          </button>
        </form>
      </div>
    );
  }
}
