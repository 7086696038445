import React, { Component } from "react";
import "./App.css";
import Socialmedia from "./components/socialmedia";
import Contact from "./components/contact";
import { BrowserRouter } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="container">
          <div className="logo-section"></div>
          <div className="footer">
            <Contact />
            <Socialmedia />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
