// Import base firebase code
import firebase from "firebase";

// Set the configuration for your app
const config = {
  apiKey: "AIzaSyBl6VTrkY6gZSc3rybfLY1xsT2ARjfVFqQ",
  authDomain: "saavik-1c0b0.firebaseapp.com",
  databaseURL: "https://saavik-1c0b0.firebaseio.com",
  projectId: "saavik-1c0b0",
  storageBucket: "saavik-1c0b0.appspot.com",
  messagingSenderId: "334489891790",
  appId: "1:334489891790:web:a0142afdb395cbb60bb19f"
};

firebase.initializeApp(config);

export default firebase;
